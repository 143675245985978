import React from 'react';
import DiscoverButton from 'screens/PlayerHome/DiscoverButton';
import AnalysesSection from './components/AnalysesSection';
import PlayerList from './components/PlayerList';
import TabSwitch from './components/TabSwitch';
import UpcomingLessons from './components/UpcomingLesson';
import { CoachHomeProps } from './types';

const CoachHomeMobile: React.FC<CoachHomeProps> = ({
  upcomingLessons,
  playerData,
  selectedPlayer,
  setSelectedPlayer,
  rankingsData,
  weeklyLessonsCount,
}) => {
  return (
    <>
      <div className="mx-auto mt-6 flex w-full flex-col px-4 xs:mb-28 lg:mb-12">
        <div className="w-full flex-col items-center justify-center border-b xs:flex md:hidden">
          <UpcomingLessons lessons={upcomingLessons} weeklyLessonsCount={weeklyLessonsCount} />
        </div>
        <AnalysesSection />
        <div className="mt-8 flex w-full flex-col self-stretch rounded-xl border border-color-border-card-lightmode shadow-md dark:border-color-border-card-darkmode  xs:p-4 md:p-5">
          <PlayerList
            players={playerData?.players}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
          />
          <div className="flex w-full flex-col items-center">
            <TabSwitch
              selectedPlayer={selectedPlayer}
              playerData={playerData}
              rankingsData={rankingsData}
            />
          </div>
        </div>

        <div className="my-8 flex justify-center">
          <DiscoverButton showOnMobile />
        </div>
      </div>
    </>
  );
};

export default CoachHomeMobile;
