import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  useGetCoachVideoAnalysisPendingLazyQuery,
  useGetCoachVideoAnalysisReviewedLazyQuery,
} from 'types/generated/client';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import NoDataPlaceholder from 'components/DiscoverPage/NoDataPlaceholder';
import LazyCardGrid from 'components/LazyCardGrid';
import AnalysisCard from 'components/VideoAnalysis/AnalysisCard';
import VideoAnalysisSwitcher from 'components/VideoAnalysis/VideoAnalysesSwitcher';
import { VideoAnalysisTabEnum } from 'components/VideoAnalysis/types';
import classNames from 'styles/utils/classNames';
import { GET_VIDEO_ANALYSIS_LAZY_LIMIT } from '../constants';
import { AnalysesSectionProps } from '../types';

const MemoisedAnalysisCard = memo(AnalysisCard);

const AnalysesSection: React.FC<AnalysesSectionProps> = () => {
  const [activeTab, setActiveTab] = useState<VideoAnalysisTabEnum>(VideoAnalysisTabEnum.Pending);

  const { user } = useGetCurrentUser();

  const [
    getCoachVideoAnalysisPendingLazyQuery,
    { data: pendingCoachVideoAnalysesData, loading: pendingCoachVideoAnalysesLoading },
  ] = useGetCoachVideoAnalysisPendingLazyQuery();

  const [
    getCoachVideoAnalysisReviewedLazyQuery,
    { data: reviewedCoachVideoAnalysesData, loading: reviewedCoachVideoAnalysesLoading },
  ] = useGetCoachVideoAnalysisReviewedLazyQuery();

  const totalPendingCoachVideoAnalyses =
    pendingCoachVideoAnalysesData?.videoAnalysisAggregate.aggregate?.count || 0;

  const totalReviewedCoachVideoAnalyses =
    reviewedCoachVideoAnalysesData?.videoAnalysisAggregate.aggregate?.count || 0;

  const handleGetCoachVideoAnalysis = useCallback(
    async (offset: number) => {
      try {
        const variables = {
          offset,
          limit: GET_VIDEO_ANALYSIS_LAZY_LIMIT,
          reviewerId: user?.id,
        };

        await (activeTab === VideoAnalysisTabEnum.Pending
          ? getCoachVideoAnalysisPendingLazyQuery
          : getCoachVideoAnalysisReviewedLazyQuery)({
          variables,
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'network-only',
        });
      } catch (err) {}
    },
    [user?.id, activeTab],
  );

  useEffect(() => {
    user?.id && handleGetCoachVideoAnalysis(0);
  }, [handleGetCoachVideoAnalysis, user?.id]);

  return (
    <section className={'max-md:mt-5 md:px-ds-2xl'}>
      <h6 className="md:typography-product-heading-compact xs:typography-product-subheading mb-5">
        Video Analysis
      </h6>

      <VideoAnalysisSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
      <div
        className={classNames('mt-10', activeTab === VideoAnalysisTabEnum.Reviewed && '!hidden')}
      >
        <LazyCardGrid
          resetTrigger={activeTab}
          uniqueKey="id"
          cards={pendingCoachVideoAnalysesData?.videoAnalysis || []}
          gap={'2rem 1rem'}
          renderCard={(card) => (
            <MemoisedAnalysisCard analysisData={card} ctaText="Give Feedback" key={card.id} />
          )}
          cardsInRow={{
            base: 1,
            md: 2,
            lg: 2,
          }}
          loadCount={GET_VIDEO_ANALYSIS_LAZY_LIMIT}
          totalAvailable={totalPendingCoachVideoAnalyses}
          onLoadMore={handleGetCoachVideoAnalysis}
          loading={pendingCoachVideoAnalysesLoading}
          loadMoreButtonProps={{
            variant: 'brand',
            title: 'Load More',
            size: 'md',
            className: 'w-fit',
          }}
        />
        {!pendingCoachVideoAnalysesLoading &&
          pendingCoachVideoAnalysesData?.videoAnalysis.length === 0 && (
            <NoDataPlaceholder className="mx-4 my-ds-xl" text="No Pending Videos" />
          )}
      </div>

      <div className={classNames('mt-10', activeTab === VideoAnalysisTabEnum.Pending && '!hidden')}>
        <LazyCardGrid
          resetTrigger={activeTab}
          uniqueKey="id"
          cards={reviewedCoachVideoAnalysesData?.videoAnalysis || []}
          gap={'2rem 1rem'}
          renderCard={(card) => (
            <MemoisedAnalysisCard analysisData={card} ctaText="See Feedback" key={card.id} />
          )}
          cardsInRow={{
            base: 1,
            md: 2,
            lg: 2,
          }}
          loadCount={GET_VIDEO_ANALYSIS_LAZY_LIMIT}
          totalAvailable={totalReviewedCoachVideoAnalyses}
          onLoadMore={handleGetCoachVideoAnalysis}
          loading={reviewedCoachVideoAnalysesLoading}
          loadMoreButtonProps={{
            variant: 'brand',
            title: 'Load More',
            size: 'md',
            className: 'w-fit',
          }}
        />
        {!reviewedCoachVideoAnalysesLoading &&
          reviewedCoachVideoAnalysesData?.videoAnalysis.length === 0 && (
            <NoDataPlaceholder className="mx-4 my-ds-xl" text="No Completed Videos" />
          )}
      </div>
    </section>
  );
};

export default AnalysesSection;
