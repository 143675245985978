import Image from 'next/image';
import { getVideoAnalysisPageUrl } from 'constants/pages';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import ArrowRight from 'svg/ArrowRight';
import Calendar from 'svg/Calendar';
import { formatDate } from 'screens/TournamentDetails/utils';
import Link from 'components/Link';
import Pill from 'components/Pill';
import { AnalysisCardProps } from './types';
import { getFormattedSkills } from './utils';

const AnalysisCard: React.FC<AnalysisCardProps> = ({ analysisData, ctaText, isPlayerViewing }) => {
  const {
    submissionRequestedFeedback,
    videoAnalysisFiles,
    createdAt,
    videoAnalysisSubmission,
    videoAnalysisReviewer,
  } = analysisData;

  console.log('isPlayerViewing', isPlayerViewing);

  const skills = getFormattedSkills(analysisData);

  const profileImageUrl = isPlayerViewing
    ? videoAnalysisReviewer?.profileImageProviderUrl
    : videoAnalysisSubmission?.profileImageProviderUrl;

  const profileImageAlt = isPlayerViewing
    ? videoAnalysisReviewer?.profileImageFileName
    : videoAnalysisSubmission?.profileImageFileName;

  return (
    <div className="flex flex-col items-start gap-3">
      <div className=" flex gap-3">
        <div className="relative h-12 w-12 rounded-full">
          <img
            src={profileImageUrl || EMPTY_AVATAR_SRC}
            alt={profileImageAlt || 'profile-image'}
            className="relative h-12 w-12 rounded-full"
          />
        </div>
        <div>
          <span className="typography-product-subheading">
            {(isPlayerViewing
              ? videoAnalysisReviewer?.fullName
              : videoAnalysisSubmission?.fullName) || 'N/a'}
          </span>
          <div className="mt-1 flex items-center gap-1">
            <Calendar className="h-5 w-5 text-color-text-lightmode-tertiary" />
            {createdAt && (
              <span className="typography-product-text-card">
                {formatDate({
                  date: createdAt,
                })}
              </span>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex items-center gap-1">
        <Calendar className="h-5 w-5 text-color-text-lightmode-tertiary" />
        <span className="typography-product-card-title-desktop text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          {formatDate({
            date: createdAt,
          })}
        </span>
      </div> */}

      <div className="flex flex-wrap gap-2">
        {skills.map((skill, index) => (
          <Pill text={skill!} key={index} />
        ))}
      </div>

      <p className="typography-product-body text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {submissionRequestedFeedback}
      </p>

      <video width="100%" controls className="mt-auto h-[20rem] rounded-xl object-contain">
        <source src={videoAnalysisFiles.url} />
        Your browser does not support the video tag.
      </video>
      <div className="flex w-full flex-row-reverse">
        <Link
          href={getVideoAnalysisPageUrl(analysisData.id)}
          className="typography-product-button-label-large mt-1 inline-flex items-center gap-1"
        >
          {ctaText} <ArrowRight className="h-5 w-5" />
        </Link>
      </div>
    </div>
  );
};

export default AnalysisCard;
